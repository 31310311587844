@import '@wearemotivated/design-system/style.css';

@tailwind components;
@tailwind utilities;

/* global styles */
body:has(.mv-full-screen-overlay) {
  @apply wf-overflow-hidden;
}

*::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
}

*::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

.landing-featured-on {
  @apply wf-pb-16 [&>div]:wf-justify-center;
}

.landing-featured-on > div:nth-child(2) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.landing-featured-on > div:nth-child(2)::-webkit-scrollbar {
  display: none;
}

.landing-testimonials {
  @apply sm:wf-gap-x-10;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.landing-testimonials::-webkit-scrollbar {
  display: none;
}

.landing-testimonial {
  @apply wf-min-w-[18rem];
}

.tw-markdown.product ul {
  @apply -wf-mb-[0.2rem] -wf-mt-[0.8rem];
}

.tw-markdown.product li {
  @apply -wf-mb-2 wf-pl-2 wf-leading-[1.4] sm:wf-pl-4;
}

.product-cta {
  @apply wf-max-w-[22.1875rem];
}

.accordion-content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.accordion-content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.product-populars {
  @apply wf-auto-cols-[minmax(200px,_max-content)] wf-px-5;
}

.product-populars > .item > .title {
  @apply wf-text-2xl;
}

.product-populars > .item > .subtitle {
  @apply wf-text-base;
}

.product-populars > .item > .image {
  @apply sm:wf-h-full sm:wf-max-h-[250px];
}

.product-testimonials {
  @apply wf-auto-cols-[minmax(280px,_max-content)] wf-pb-9 sm:wf-pb-28;
}

.featured-on .container {
  @apply wf-px-4 sm:wf-justify-start sm:wf-px-12 lg:wf-justify-center;
}

.what-is-product {
  @apply sm:wf-min-h-[8.75rem];
}

.step-second-item {
  @apply sm:wf-min-h-[15rem];
}

.product-features > .content > .action > a {
  @apply sm:wf-max-w-[22.1875rem];
}

.product-features > .background {
  @apply wf-max-h-[50rem];
}

.sd-question__content > div,
.sd-row--multiple {
  @apply wf-w-full sm:wf-max-w-[28.5rem] !important;
}

.checkout-content > button,
.checkout-content button[type='submit'],
[type='submit']:not(.mv-rdsn-submit) {
  @apply wf-bg-primary;
}

.checkout-content button[type='submit']:disabled {
  @apply wf-bg-primaryLight;
}

.wizard-container {
  @apply wf-pb-10 sm:wf-pb-16;
}

.invisible {
  visibility: hidden;
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-out-right {
  animation: slideOutRight 0.5s forwards;
}

.slide-out-position {
  transform: translateX(100%);
  opacity: 0;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rdsnFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-in-left {
  animation: slideInRight 0.5s forwards;
}

.mv-wizard-redesign.mv-wizard-page-transition .mv-survey-next-btn,
.mv-wizard-redesign.mv-wizard-page-transition .sd-btn.sd-navigation__next-btn {
  animation: 0.2s ease-in-out 0s forwards rdsnFadeOut;
}

@keyframes rdsnFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rdsn-fade-in {
  animation: rdsnFadeIn 0.5s forwards;
}

.video-testimonial-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.video-testimonial-card .video-testimonial-card-content {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mv-no-overflow {
  @apply wf-overflow-hidden;
}

@keyframes mv-scroll-mq {
  to {
    transform: translateX(calc(-50% - var(--mv-mq-adj, 1rem)));
  }
}
@keyframes mv-scroll-lg-mq {
  to {
    transform: translateX(calc(-50% - var(--mv-mq-lg-adj, 2rem)));
  }
}

@media (width >= 640px) {
  .mv-mq-container-sm {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 12.5%,
      rgb(0, 0, 0) 87.5%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.mv-mq-container {
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
}

.mv-quote-left-border {
  text-indent: -0.5em;
}

.mv-quote-left-border::before {
  content: open-quote;
  @apply wf-text-rdsn-h1;
}
.mv-quote-left-border::after {
  content: close-quote;
  @apply wf-text-rdsn-h1;
}

@keyframes mv-animation-loading-steps {
  0% {
    width: 0%;
  }
  10% {
    width: 10%;
  }
  40% {
    width: 40%;
  }
  60% {
    width: 50%;
  }
  100% {
    width: 70%;
  }
}

@keyframes mv-animation-loading-done {
  0% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}

.mv-anim-loading-progress {
  animation: mv-animation-loading-steps var(--mv-load-steps-duration, 3s) linear
    var(--mv-load-steps-delay, 1s) forwards;
}

.mv-anim-loading-done {
  animation: mv-animation-loading-done var(--mv-load-done-duration, 0.5s) linear
    var(--mv-load-done-delay, 0s) forwards;
}
